import 'normalize.css';
import './fonts/fonts.css';
import 'aos/dist/aos.css';
import './styles/app.scss';

import $ from 'jquery';
// import AOS from 'aos';
import ScrollMagic from 'scrollmagic';
import './libs/jquery-mousewheel';
import { imageSrc } from './libs/imgSrc';
import Loading from './assets/images/loading.png';
// import { loadingBase64 } from './libs/base64src';

export default class App {
  stopCanvas = false;
  precent = 0;
  timer: NodeJS.Timer | null = null;
  time = 0;
  loadCompleted = false;

  run() {
    this.initLoading();
    this.loadResources();
    this.initAOS();
    this.initAOS();
    this.initNav();
    this.roadmapScroll()
    this.initMobileMenu();
  }

  initWow() {

  }

  initAOS() {
    // $(() => {
    //   AOS.init({
    //     anchorPlacement: 'center-center',
    //     mirror: true,
    //     disable: 'mobile',
    //     offset: -600,
    //     duration: 500,
    //   });
    //   // You can also pass an optional settings object
    //   // below listed default settings
    //   // AOS.init({
    //   //   // Global settings:
    //   //   disable: false, // accepts following values: 'phone', 'tablet', 'mobile', boolean, expression or function
    //   //   startEvent: 'DOMContentLoaded', // name of the event dispatched on the document, that AOS should initialize on
    //   //   initClassName: 'aos-init', // class applied after initialization
    //   //   animatedClassName: 'aos-animate', // class applied on animation
    //   //   useClassNames: false, // if true, will add content of `data-aos` as classes on scroll
    //   //   disableMutationObserver: false, // disables automatic mutations' detections (advanced)
    //   //   debounceDelay: 50, // the delay on debounce used while resizing window (advanced)
    //   //   throttleDelay: 99, // the delay on throttle used while scrolling the page (advanced)
    //   //   // Settings that can be overridden on per-element basis, by `data-aos-*` attributes:
    //   //   offset: 120, // offset (in px) from the original trigger point
    //   //   delay: 0, // values from 0 to 3000, with step 50ms
    //   //   duration: 400, // values from 0 to 3000, with step 50ms
    //   //   easing: 'ease', // default easing for AOS animations
    //   //   once: false, // whether animation should happen only once - while scrolling down
    //   //   mirror: false, // whether elements should animate out while scrolling past them
    //   //   anchorPlacement: 'top-bottom', // defines which position of the element regarding to window should trigger the animation
    //   // });
    //   console.log('init finsh')
    // })
  }

  initNav() {
    const controller = new ScrollMagic.Controller({ globalSceneOptions: { triggerHook: 1, loglevel: 1, }, });

    const app = document.getElementById('app');
    // @ts-ignore
    const scene1 = new ScrollMagic.Scene({ duration: app.clientHeight, triggerElement: '#app' })
      .setClassToggle('#nav-app', 'active') // add class toggle
      .addTo(controller);
    scene1.update(true);
    const toolkit = document.getElementById('toolkit');
    // @ts-ignore
    new ScrollMagic.Scene({ duration: toolkit.clientHeight, triggerElement: '#toolkit' })
      .setClassToggle('#nav-toolkit', 'active') // add class toggle
      .addTo(controller);
    const roadmap = document.getElementById('roadmap');
    // @ts-ignore
    new ScrollMagic.Scene({ duration: roadmap.clientHeight, triggerElement: '#roadmap' })
      .setClassToggle('#nav-roadmap', 'active') // add class toggle
      .addTo(controller);


    $('.header-wrap-nav-item').on('click', (e) => {
      const navTo = $(e.target).data('navTo');
      if (navTo) {
        const offset = $(`#${navTo}`).offset();
        if (offset) {
          $('body, html').animate({
            scrollTop: offset.top,
          });
        }
      }
    });

    $('.header-mobile-menu-wrap-item').on('click', (e) => {
      const navTo = $(e.target).data('navTo');
      if (navTo) {
        const menuButton = $('.menu') as JQuery<HTMLElement>;
        menuButton.toggleClass('opened');
        menuButton.attr('aria-expanded', menuButton.hasClass('opened').toString());
        const menu = $('.header-mobile-menu') as JQuery<HTMLElement>;
        menu.toggleClass('openMenu');

        $('html').css('width', 'auto');
        $('html').css('height', 'auto');
        $('html').css('overflow-x', 'hidden');
        $('html').css('overflow-y', 'scroll');

        setTimeout(() => {
          const offset = $(`#${navTo}`).offset();
          if (offset) {
            $('body, html').animate({
              scrollTop: offset.top,
            });
          }
        }, 300);
      }
    });

    $('.header-wrap-logo').on('click', () => {
      $('body, html').animate({
        scrollTop: 0,
      });
    });
  }

  roadmapScroll() {
    // // @ts-ignore
    // $('.roadmap-wrap-scroll').mousewheel(function (event) {
    //   if (event.stopPropagation) {
    //     event.stopPropagation();
    //   } else {
    //     event.cancelBubble = true;
    //   }
    //   if (event.preventDefault) {
    //     event.preventDefault();
    //   } else {
    //     event.returnValue = false;
    //   }

    //   if (event.deltaY < 0) {
    //     // @ts-ignore
    //     $('.roadmap-wrap-scroll').scrollLeft($('.roadmap-wrap-scroll').scrollLeft() + 60);
    //   } else if (event.deltaY > 0) {
    //     // @ts-ignore
    //     $('.roadmap-wrap-scroll').scrollLeft($('.roadmap-wrap-scroll').scrollLeft() - 60);
    //   }
    // })

    const slider = $('.roadmap-wrap-scroll') as JQuery<HTMLElement>;
    let isDown = false;
    let startX = 0;
    let scrollLeft: number | undefined = 0;

    slider.on('mousedown', (e) => {
      isDown = true;
      const offsetLeft = slider.offset()?.left ?? 0;
      startX = e.pageX - offsetLeft;
      scrollLeft = slider.scrollLeft();
    });

    slider.on('mouseleave', () => {
      isDown = false;
    });

    slider.on('mouseup', () => {
      isDown = false;
    });

    slider.on('mousemove', (e) => {
      if (!isDown) return;
      e.preventDefault();
      const offsetLeft = slider.offset()?.left ?? 0;
      const x = e.pageX - offsetLeft;
      const walk = (x - startX) * 1.2;
      slider.scrollLeft((scrollLeft ?? 0) - walk);
    });
  }

  initMobileMenu() {
    $('.menu').on('click', function () {
      this.classList.toggle('opened');
      this.setAttribute('aria-expanded', this.classList.contains('opened').toString());
      if (this.classList.contains('opened')) {
        $('html').css('width', '100vw');
        $('html').css('height', '100vh');
        $('html').css('overflow', 'hidden');
      } else {
        $('html').css('width', 'auto');
        $('html').css('height', 'auto');
        $('html').css('overflow-x', 'hidden');
        $('html').css('overflow-y', 'scroll');
      }
      const menu = $('.header-mobile-menu') as JQuery<HTMLElement>;
      menu.toggleClass('openMenu');
    })
  }

  async initLoading() {
    const imgArr = [];

    for (let column = 0; column < 8; column++) {
      for (let row = 0; row < 5; row++) {
        if (column === 7 && row > 2) break;

        imgArr.push([row, column]);
      }
    }

    const canvas = document.getElementById('loadingCanvas') as HTMLCanvasElement;
    const AR = 126 / 126;
    canvas.width = 126;
    canvas.height = 126 / AR;

    let i = 0;
    while (!this.stopCanvas) {
      await this.drawImage(canvas, Loading, imgArr[i][0] * 126, imgArr[i][1] * 126);
      if (i === 36) {
        i = 0
      } else {
        i++
      }
    }
  }

  async drawImage(canvas: HTMLCanvasElement, data: string, dx: number, dy: number) {
    return new Promise((resolve) => {
      const image = new Image();
      // image.src = `data:image/png;base64,${data}`;
      image.src = Loading;
      setTimeout(() => {
        // @ts-ignore
        const context = canvas.getContext('2d') as CanvasRenderingContext2D;
        context.clearRect(0, 0, canvas.width, canvas.height);
        context.drawImage(image, -dx, -dy, 900 * 0.7, 1440 * 0.7);
        resolve(true);
      }, 68);
    });
  }

  changeProgress(count: number) {
    this.precent = count / imageSrc.length;
    // $('.screen-loading-wrap-loading-inner').width(this.precent * 180);
  }

  async loadResources() {
    this.timer = setInterval(() => {
      if (this.time >= 1 && this.loadCompleted) {
        // @ts-ignore
        clearInterval(this.timer);
        this.timer = null;
        this.loadingOver();
      } else if (this.time >= 1 && !this.loadCompleted) {
        // @ts-ignore
        clearInterval(this.timer);
        this.timer = null;
      }
      this.time += 1;
    }, 1000);
    const promiseArr: Promise<boolean>[] = [];
    let currentImageCount = 0;
    imageSrc.forEach(img => {
      promiseArr.push(new Promise(resolve => {
        const image = new Image();
        image.onload = () => {
          currentImageCount += 1;
          this.changeProgress(currentImageCount);
          resolve(true);
        }
        image.src = img;
      }));
    });
    await Promise.all(promiseArr);
    this.loadCompleted = true;
    if (!this.timer) {
      this.loadingOver();
    }
  }

  sleep(time: number = 500) {
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(true);
      }, time);
    });
  }

  async loadingOver() {
    this.stopCanvas = true;
    $('.screen-loading-wrap').fadeOut(500);
    await this.sleep(200);
    $('.screen-loading-left').addClass('open-left');
    $('.screen-loading-right').addClass('open-right');
    await this.sleep(2000);
    $('.screen-loading').css('z-index', '-1');
  }
}
