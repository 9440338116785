const getPublicUrl = (name: string) => {
  return require(`../assets/videoImages/${name}.jpg`).default
};
const getAssetUrl = (name: string) => require(`../assets/images/${name}.png`).default;

export const imageSrc = [
  getAssetUrl('loading'),
  getPublicUrl('video'),
  getPublicUrl('mobile-video'),
  getPublicUrl('boo-mart'),
  getPublicUrl('click-modal'),
  getPublicUrl('enter-world'),
  getPublicUrl('first-page'),
  getPublicUrl('me'),
  getPublicUrl('regist'),
  getAssetUrl('arrow-drag'),
  getAssetUrl('bg-grid'),
  getAssetUrl('bg-video-2'),
  getAssetUrl('bg-video'),
  getAssetUrl('icon-arrow'),
  getAssetUrl('icon-avatar'),
  getAssetUrl('icon-discord-active'),
  getAssetUrl('icon-instagram'),
  getAssetUrl('icon-instagram-active'),
  getAssetUrl('icon-item'),
  getAssetUrl('icon-live'),
  getAssetUrl('icon-twitter-active'),
  getAssetUrl('icon-twitter'),
  getAssetUrl('icon-world'),
  getAssetUrl('image-avatar'),
  getAssetUrl('image-character'),
  getAssetUrl('image-data'),
  getAssetUrl('image-mraketplace'),
  getAssetUrl('image-profile'),
  getAssetUrl('image-share'),
  getAssetUrl('image-social'),
  getAssetUrl('image-swap'),
  getAssetUrl('image-wallet'),
  getAssetUrl('logo-first'),
  getAssetUrl('logo-footer'),
  getAssetUrl('logo-header'),
  getAssetUrl('studio'),
  getAssetUrl('title-app'),
  getAssetUrl('title-roadmap'),
  getAssetUrl('title-toolkit'),
];
