import './index.css'
import App from './App'

if (process.env.NODE_ENV === 'production') {
  console.log = () => { };
  console.error = () => { };
  console.warn = () => { };
}

const main = async (): Promise<void> => {
  await (new App()).run();
}


main().then(() => console.log('finish'));
